import React from "react";

// import "../Button.css";

export default function Button(props) {
  return (
    <>
      <br></br>
      <button>{props.title}</button>
    </>
  );
}

import React from "react";
import ContentContainer from "../components/ContentContainer/ContentContainer";
import Footer from "../components/Footer/Footer";
import NavBar from "../components/NavBar/NavBar";
import data from "../data/BoardGamesContent.json";

function BoardGamesPage() {
  return (
    <>
      <NavBar></NavBar>
      <ContentContainer
        title={data[0].title}
        description={data[0].description}
        products={data[0].products}
      ></ContentContainer>
      <Footer></Footer>
    </>
  );
}

export default BoardGamesPage;
